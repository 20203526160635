<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Цуцлалтын тайлан</h2>
        </el-col>
        <el-col :span="12">
          <div class="text-right">
            <vue-excel-xlsx
              v-if="cancelDataNoPag.length !== 0"
              style="border: none"
              :data="cancelDataNoPag"
              :columns="generateExcelData()"
              :file-name="'Цуцлалтын тайлан'"
              :file-type="'xlsx'"
              :sheet-name="'Хүлээгдэж буй'"
              >
              <el-button type="success" icon="el-icon-download" size="small">Татаж авах (.xlsx)</el-button>
            </vue-excel-xlsx>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabName">
        <el-tab-pane :label="`Бүгд (${ totalCount })`" name="all">
      <div class="panel-content">
        <div class="pl20 pr20 table-filter mb10">
          <el-row :gutter="10" align="middle" type="flex">
            <el-col :span="5">
              <el-select placeholder="Брэнд сонгох" v-model="selectedBrand" multiple filterable clearable @change="changeFilter()">
                <el-option
                  v-for="(item, brandIndex) in getBrands"
                  :key="brandIndex"
                  :label="item.brand_monName"
                  :value="item.brand_id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="20">
              <el-date-picker
               @change="changeFilter()"
                v-model="dateTime"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                range-separator="-"
                start-placeholder="Эхлэх огноо"
                end-placeholder="Дуусах огноо"
                align="right">
              </el-date-picker>
            </el-col>
          </el-row>
        </div>
        <cancel-table :isLoading='isLoading' :cancelData='cancelData' :totalCount="totalCount" :curentPageHandler="curentPageHandler" :sizeChangeHandler="sizeChangeHandler" :pageSize="pageSize" :currentPage="currentPage"></cancel-table>
      </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import cancelTable from './components/cancelTable'
import services from '../../../helpers/services'
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '7 хоног',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 сар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 сар',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      dateTime: [],
      getBrands: [],
      selectedBrand: [],
      activeTabName: 'all',
      cancelData: [],
      cancelDataNoPag: [],
      totalCount: 0,
      pageSize: this.$route.query.size ? JSON.parse(this.$route.query.size) : 20,
      currentPage: 1,
      pageFrom: 0,
      isLoading: false
    }
  },
  async mounted () {
    const newDate = new Date()
    const date = newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-'
    const startDate = date + '01' + ' ' + '00:00:00'
    const endDate = date + newDate.getDate() + ' ' + newDate.getHours() + ':' + newDate.getMinutes() + ':' + newDate.getSeconds()
    this.dateTime.push(startDate, endDate)
    // pagination
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.changeFilter()
    this.getBrand()
  },
  methods: {
    changeFilter () {
      this.currentPage = 1
      if (this.$route.query.page !== '1' && this.$route.query.page !== 1) {
        this.$router.push({ name: 'cancelReport', query: { page: this.currentPage, size: this.pageSize } })
      }
      this.cancelReport(this.pageSize, this.pageFrom)
    },
    priceFormat (value) {
      return parseFloat(value) + '₮'
    },
    floatFormat (value) {
      return parseFloat(value)
    },
    generateExcelData  () {
      const tempData = [
        {
          label: 'Брэнд',
          field: 'brand_name'
        },
        {
          label: 'Бүтээгдэхүүний нэр',
          field: 'product_name'
        },
        {
          label: 'Тоо ширхэг',
          field: 'item_count',
          dataFormat: this.floatFormat
        },
        {
          label: 'Нэгж үнэ',
          field: 'menu_price',
          dataFormat: this.priceFormat
        },
        {
          label: 'Нийт үнэ',
          field: 'total_price',
          dataFormat: this.priceFormat
        },
        {
          label: 'Шалтгаан',
          field: 'reason'
        },
        {
          label: 'Захиалсан хугацаа',
          field: 'createdAt'
        }
      ]
      return tempData
    },
    sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'cancelReport', query: { page: this.currentPage, size: size } })
      this.cancelReport(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'cancelReport', query: { page: current, size: this.pageSize } })
      this.cancelReport(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },
    getBrand () {
      const query = '?size=1000' + '&from=0'
      services.getBrand(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.getBrands = response.data
        }
      })
    },
    cancelReportNoPag () {
      const body = {
        type: 'cancelled_order',
        start_date: this.dateTime ? this.dateTime[0] : '',
        end_date: this.dateTime ? this.dateTime[1] : '',
        brand_id: this.selectedBrand.length > 0 ? this.selectedBrand : []
      }
      services.salesReportNoPag(body).then(response => {
        if (response.status === 'success') {
          this.cancelDataNoPag = response.data
        }
      })
    },
    async cancelReport (size, from) {
      this.isLoading = true
      this.cancelReportNoPag()
      const body = {
        size: size,
        from: from,
        type: 'cancelled_order',
        start_date: this.dateTime ? this.dateTime[0] : '',
        end_date: this.dateTime ? this.dateTime[1] : '',
        brand_id: this.selectedBrand.length > 0 ? this.selectedBrand : []
      }
      services.salesReport(body).then(response => {
        if (response.status === 'success') {
          this.totalCount = response.count
          this.cancelData = response.data
          this.isLoading = false
        }
      })
    }
  },
  components: {
    cancelTable
  }
}
</script>

<style>

</style>
