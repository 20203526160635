<template>
  <div>
    <el-table
      v-loading="isLoading"
      :data="cancelData"
      style="width: 100%"
      size="small"
    >
      <el-table-column label="Захиалгын дугаар" prop="order_number" width="125px">
      </el-table-column>
      <el-table-column label="Брэнд" prop="brand_name" width="100"></el-table-column>
      <el-table-column label="Бүтээгдэхүүний нэр" prop="product_name" width="150">
      </el-table-column>
      <el-table-column label="Харилцагчийн SKU код" prop="supplier_sku" width="160">
      </el-table-column>
      <el-table-column label="Тоо ширхэг" width="100">
        <template slot-scope="scope">
          {{ parseFloat(scope.row.item_count) }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="driver_name" label="Жолооч">
      </el-table-column> -->
      <el-table-column label="Нэгж үнэ" prop="menu_price" width="100">
        <template slot-scope="scope">
          {{ generateCurrency(scope.row.menu_price) }}
        </template>
    </el-table-column>
      <el-table-column label="Нийт үнэ" width="125">
        <template slot-scope="scope">
          <span>{{ generateCurrency(scope.row.total_price) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Төлөв" prop="order_status" width="100">
          <template slot-scope="scope">
              <el-tag :type="scope.row.order_status === 'Амжилттай' ? 'success' : scope.row.order_status === 'Цуцалсан' ? 'danger' : ''">{{ scope.row.order_status }}</el-tag>
          </template>
      </el-table-column>
      <el-table-column label="Төлбөрийн статус" width="130px" prop="pay_status">
      </el-table-column>
      <el-table-column label="Шалтгаан" width="200" prop="reason">
      </el-table-column>
      <el-table-column label="Захиалга орсон хугацаа" width="160">
          <template slot-scope="scope">
              <span>{{ scope.row.createdAt }}</span>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import helper from '../../../../helpers/helper'
// import { getSupplierId } from '../../../../utils/auth'
export default {
  name: 'orderTable',
  props: {
    cancelData: {
      type: Array
    },
    pageSize: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalCount: {
      type: Number,
      default: 0
    },
    curentPageHandler: {
      type: Function
    },
    sizeChangeHandler: {
      type: Function
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // page: 1,
      isloading: false
    }
  },
  methods: {
    formatPhone (val) {
      if (val) {
        val = val.toString()
        return val[0] + val[val.length - 1].toString().padStart(val.length - 1, '*')
      } else {
        return '********'
      }
    },
    formatDate (val) {
      return helper.formatDate(val)
    },
    filterHandler (value, row) {
      return row.driver_name === value
    },
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    }
    // clickCell (row, column, cell, event) {
    //   this.$router.push(
    //     {
    //       name: 'orderDetail',
    //       params: {
    //         id: row.id
    //       }
    //     }
    //   )
    // }
  }
}
</script>
